<template>
	<div>
		<div class="sidebar">
			<div class="sidebar__logo">
				<img alt="" src="../../assets/logo_1.png">
			</div>
			<div class="sidebar__menu menu">
				<ul class="menu__content">
					<li
						:class="{ 'active' : item.route === $route.path }"
						class="menu__item"
						v-for="item in sidebars"
						:key="item.name"
					>
						<router-link :to="item.route">
							<i :class="item.icon"></i> {{item.name }}
						</router-link>
					</li>
				</ul>
			</div>
			<div class="sidebar__logout" @click="$router.push('/')"><i class="fas fa-lock">Выйти</i> 
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "Sidebar",
	props: { sidebars: { type: Array, default: () => {} } },
	data() {
		return {
			
		}
	},
	computed: {
		loggedIn() {
			return this.$store.state.account.user;
        },
        
    },
}
</script>

<style lang="scss" scoped>
.sidebar {
	width: 230px;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	background: #089bab;
	font-family: Roboto, sans-serif;
	font-style: normal;
    font-size:14px;
	font-weight: 400;
	display: flex;
	flex-direction: column;
	z-index: 2;

	&__logo {
		font-size: 19px;
		line-height: 22px;
		letter-spacing: 0.4px;
		color: #FFFFFF;
		height: 85px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			padding: 30px;
            width:150px;
		}
	}

	&__menu {
		flex: 1;
		overflow: auto;

		&::-webkit-scrollbar              {
			width: 10px;
			border-radius: 10px;
		}
		::-webkit-scrollbar-button       {  }
		&::-webkit-scrollbar-track        {
			-webkit-box-shadow: inset 0 0 6px #089bab;
			border-radius: 10px;
			background-color: #089bab;
		}
		&::-webkit-scrollbar-track-piece  {
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb        {
			border-radius: 10px;
			-webkit-box-shadow: inset 0 0 6px #089bab;
			background-color: #089bab;
		}
		&::-webkit-scrollbar-corner       {
			border-radius: 10px;
		}
		::-webkit-resizer                {  }
	}

	&__logout {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 40px;
		border-radius:10px;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.2px;
		color: #ffffff;

		&:hover {
			//opacity: 0.2;
			cursor: pointer;
            background: #fcfcfc;
            height: 30px;
            font-weight:700;
			box-shadow: 0px 5px 15px rgba(7, 8, 8, 0.5);
			color: #089bab;
			border-radius:10px;
		}

		img {
			margin-right: 20px;
		}
	}
}

.menu {
	&__content {
		list-style-type: none;
		margin-top: 30px;
		padding: 0;
        
        font-weight:700;
	}

	&__item {
		font-weight: 300;
		font-size: 14px;
		line-height: 19px;
		letter-spacing: 0.2px;
		color: #ffffff;
		border-radius:10px;
        font-size:14px;
        font-weight:400;
		transition: all 400ms;
		cursor: pointer;

		a {
			display: block;
			padding: 15px 24px;
			height: 100%;
			width: 100%;
			color: inherit;
			text-decoration: none;
		}

		&:hover {
			background: #fcfcfc;
            margin-left:20px;
			border-radius:10px;
            width:210px;
            font-weight:700;
			box-shadow: 0px 5px 15px rgba(7, 8, 8, 0.5);
			color: #089bab;
			i {
				color: #089bab;
			}
		}
		&.active {
			background: #ffffff;
			color: #089bab;
            margin-left:20px;
            width:210px;
            font-weight:700;
            
			i {
				color: #089bab;
			}
		}

		i {
			margin-right: 5px;
			color: #ffffff;
		}
	}
}
</style>
